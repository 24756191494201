.main-container {
    position: relative;
    right: -180px;
    width: 80%;
    height: auto;
    margin-top: 2vh;
    margin-left: 4vh;
}

@media (max-width: 480px) {
    .main-container {
        position: initial;
        width: 100%;
        height: auto;
        padding: 30px 0 0 20px;
        margin: 0;
    }
}
