.sidemenu-container.projects + .main-container {
    height: 97vh;
    margin-top: 0;
}

.image-info {
    display: flex;
    justify-content: center;
    min-height: calc(40px + 1.3em);
    width: 95%;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    width: 100px;
    height: 67px;
    position: absolute;
    right: 14vh;
    bottom: 0;
}

.main-container > .info {
    top: calc(89vh - 2px);
}

.info > button {
    background: none;
    border: none;
    margin-top: 0.3vh;
    border-bottom: 0.15vh solid transparent;
    padding: 0;
    cursor: pointer;
    outline: none;
}

.info > button:hover,
.info > button.active {
    border-bottom: 0.15vh solid black;
}

.pages {

}

.image-title {
    font-size: 1em;
    margin: 20px 0;
}

.slide-container {
    outline: none;
    position: relative;
    top: 2vh;
    display: flex;
    flex-direction: column;
    height: max-content;
}

.projectInfo {
    position: absolute;
    top: 2vh;
    left: 0;
    right: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12vh;
    height: 62.8vh;
    max-width: 84.5vh;
    margin: auto;
    border-style: solid;
    border-color: white;
    border-width: 0.15vh;
    overflow: hidden auto;
}

.projectInfo::before {
    content: "X";
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-family: "Gruppo", sans-serif;
    font-weight: bolder;
    font-size: 1.1em;
    width: 20px;
}

.projectInfo > p {
    line-height: 1.5em !important;
    margin: 1.4vh;
}

.leftCursor {
    cursor: url("../assets/left.svg"), auto;
}

.rightCursor {
    cursor: url("../assets/right.svg"), auto;
}

@media (max-width: 480px) {
    .slide-container .media {
        right: -1px;
    }

    .image-title {
        display: none;
    }

    .info {
        position: initial;
        float: right;
        margin-right: 20px;
    }

    .projectInfo {
        position: relative;
        justify-content: start;
        height: max-content;
        top: 0;
        margin: 0 20px 0 0;
        padding: 0;
        border: none;
    }

    .projectInfo p {
        overflow: visible;
    }

    .projectInfo::before {
        content: none;
    }

    .image-info {
        flex-direction: row-reverse;
        width: 90%;
        min-height: initial;
    }

    .sidemenu-container.projects + .main-container {
        padding-left: 0;
        height: auto;
    }
}
