@font-face {
    font-family: "Alpina";
    src: local("Alpina"),
        url(./fonts/GT-Alpina-Standard-Regular.ttf) format("truetype");
}

* {
    font-family: "Alpina", serif;
    font-size: 1.015em;
    line-height: 1.3em;
    color: black;
    text-decoration: none;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden auto;
}
