.about {
    margin: 8vh 4vh 0 25%;
    padding-left: 4vh;
    padding-right: 12vh;
    height: 100%;
}

.about p {
    margin-bottom: 1vh;
}

.con {
    margin-bottom: 0 !important;
}

.rights {
    padding-top: 2vh;
}

@media (max-width: 480px) {
    .about {
        margin: 12vw 5vw 30px 0;
        padding: 0;
    }

    .about p {
        position: initial;
        padding: 0;
        overflow: visible;
    }
}
