.App {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    overflow: hidden auto;
}

.media {
    width: 94%;
    height: 87vh;
    object-fit: contain;
    margin-left: 1vh;
}

@media (max-width: 480px) {
    .App {
        flex-direction: column-reverse;
        margin-left: 5vw;
        font-size: 1.1em;
        line-height: 1.35em;
    }

    .media {
        position: relative;
        right: 2vh;
        height: 100%;
        margin-top: 10.2vw;
        margin-right: 0;
        margin-left: 0;
    }
}
