.sidemenu-container {
    position: fixed;
    left: 5vh;
    top: 3vh;
    width: 21%;
    height: 98%;
    z-index: 1;
}

.sidemenu-container .logo {
    height: 42px;
    margin-bottom: 2vh;
}

.sidemenu-container .active {
    border-bottom: 0.15vh solid black;
}

.sidemenu .projects {
    height: 65vh;
    overflow: visible auto;
    padding-top: 1vh;
    padding-right: 6vh;
}

.sidemenu .projects ul {
    margin-top: 0;
    margin-left: 0;
    padding: 0;
}

.sidemenu .projects li {
    list-style: none;
    padding-bottom: 2vh;
}

.sidemenu .projects a:hover,
.sidemenu .menuabout a:hover,
.sidemenu .commissions a:hover {
      border-bottom: 0.15vh solid black;
}

.sidemenu .commissions {
    margin-bottom: 1vh;
}

.sidemenu .infos p {
    margin: 0.5vh 0.5vh 0.5vh 0;
}

.sidemenu .infos .header {
    display: block;
    margin-bottom: 4vh;
}

.sidemenu .menuabout,
.sidemenu .commissions {
    margin-top: 3.5vh;
}

@media (max-width: 480px) {
    .sidemenu-container {
        width: 100%;
        height: initial;
        position: initial;
        overflow: visible;
    }

    .sidemenu-container .logo {
        position: absolute;
        top: 0;
        height: 44px;
        margin-top: 5vw;
    }

    .sidemenu {
      margin-top: 5vw;
    }

    .sidemenu .projects {
        height: 100%;
    }

    .sidemenu-container.aboutView {
        order: 1;
    }

    .sidemenu-container.aboutView .logo {
        position: initial;
        margin-top: 3vw;
    }

    .sidemenu-container + .about {
        margin-top: 10px;
    }

    .sidemenu-container + .about p:first-child {
        margin-top: 0;
    }

    .sidemenu-container.projects .sidemenu,
    .sidemenu-container.aboutView .sidemenu {
        display: none;
    }
}
